<template>
  <div class="orderform-container">
    <loadingPage :isload="loadState"></loadingPage>
    <!-- 地址 -->
    <div class="orderform-address" @click="openSelectAddress">
      <div class='address-img'>
        <img src="@/assets/orderForm/address.png" alt="">
      </div>
      <!-- 地址信息/联系人信息 -->
      <div class="address-people" v-if="JSON.stringify(addressInfo) != '{}'">
        <div class="people-box">
          <span class="call-name">{{addressInfo.recipient_name}}</span>
          <span class="call-phone">{{addressInfo.recipient_phone}}</span>
        </div>
        <!-- 地址信息 -->
        <div class="address-info">
          {{addressInfo.province_name}}{{addressInfo.city_name}}{{addressInfo.area_name}}{{addressInfo.address}}
        </div>
      </div>
      <!-- 在没有信息的情况下 -->
      <div class="no-address-people" v-else>
        请添加地址信息
      </div>
    </div>
    <!-- 商品列表 -->
    <orderFormProduct v-for="item in productList" :key="item.id" :id="item.id" :name="item.name" :cover="item.cover"
      :price="item.real_price" :num="item.number" @deletProduct="deletThisProduct" :price_unit="item.price_unit" :attrbute_id="item.attrbute_id"
      @changeProductNum="changeProductNumber">
    </orderFormProduct>
    <!-- 安全提示 -->
    <div class="product-safety">
      <div class="safety-img">
        <img src="@/assets/orderForm/safety.png" alt="">
      </div>
      <!-- 提示文字 -->
      <div class="hint">
        如您购买的商品有任何问题，请直接与购买门店联系，门店会为您处理，让您售后无忧
      </div>
    </div>
    <!-- 提交订单按钮 -->
    <div class="get-btn-box">
      <div class="get-price">
        应付：<span>￥{{ totalPrices }}</span>
      </div>
      <!-- <span class="price">应付: ￥29099</span> -->
      <div class="get-btn">
        <van-button type="primary" @click="submitMyNowOrder">立即预约</van-button>
      </div>
    </div>
    <!-- 地址列表 -->
    <transition name="fade">
      <selectHarvestAddress v-if="selectShow" @closeSelectAddress="closeAddress" @getCheckMyAddress="getCheckMyAddress"></selectHarvestAddress>
    </transition>

    <!-- <van-dialog v-model="DiaLogshow" title="订单提交成功" theme="round-button">
    </van-dialog> -->
  </div>
</template>

<script>
import orderFormProduct from '@/components/orderFormProduct.vue'
import selectHarvestAddress from '@/components/selectHarvestAddress/selectHarvestAddress.vue'
import loadingPage from '@/components/loadingPage.vue'
import { getMyOrder, deleteOrderGoods, getcheckAddressInfo, submitNowOrder, submitGoodsOrder } from '@/api/goodsApi.js'
import { Toast } from 'vant'
export default {
  name: 'orderForm',
  props: {
    Category: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      page: 1,
      page_size: 6,
      addressInfo: {},
      productList: [],
      show: true,
      selectShow: false,
      DiaLogshow: true,
      loadState: true,
      CategoryUrl: ''
    }
  },
  created () {
    this.getMyOrderInfo()
    Toast.allowMultiple()
  },
  methods: {
    // 获取当前所以订单以及默认地址
    async getMyOrderInfo () {
      if (this.Category === 'goods') {
        this.CategoryUrl = ''
      } else {
        this.CategoryUrl = this.Category + '_'
        console.log(this.CategoryUrl)
      }
      this.addressInfo = {}
      const res = await getMyOrder(this.CategoryUrl)
      this.loadState = false
      if (res.code !== 200) {
        Toast.fail('订单拉取失败')
        return
      }
      if (res.data.address !== '') {
        this.addressInfo = res.data.address
      }

      console.log(this.addressInfo)
      this.productList = res.data.goods_list
      console.log(res)
    },
    async deletThisProduct (id) {
      Toast.loading({
        message: '',
        forbidClick: true
      })
      const res = await deleteOrderGoods({
        goods_id: id
      }, this.CategoryUrl)
      console.log(res)
      if (res.code !== 200) {
        Toast.fail('商品移除失败')
        return
      }
      Toast.clear()
      const result = this.productList.filter(item => item.id !== id)
      this.productList = result
    },
    async changeProductNumber (id, number, attrbuteId) {
      this.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true
      })
      const res = await submitGoodsOrder({
        goods_id: id,
        attrbute_id: attrbuteId,
        number
      }, this.CategoryUrl)
      this.$toast.clear()
      if (res.code !== 200) {
        this.$toast({
          type: 'fail',
          message: '商品增加失败',
          forbidClick: true
        })
        return
      }
      this.productList.forEach((item, index) => {
        if (item.id === id) {
          console.log(item.id, number)
          item.number = number
        }
      })
    },
    // 打开地址选择
    openSelectAddress () {
      this.selectShow = true
    },
    closeAddress () {
      this.selectShow = false
      // this.getMyOrderInfo()
      // console.log(111)
    },
    // 获取我选中的地址
    async getCheckMyAddress (checkAddressDetail) {
      this.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true
      })
      const res = await getcheckAddressInfo({
        address_id: checkAddressDetail.address_id
      })
      Toast.clear()
      if (res.code !== 200) {
        Toast.fail('地址修改失败')
        return
      }

      this.addressInfo = checkAddressDetail
    },
    async submitMyNowOrder () {
      if (JSON.stringify(this.addressInfo) === '{}') {
        this.$toast({
          type: 'fail',
          message: '请先选择地址',
          forbidClick: true
        })
        return
      }
      const res = await submitNowOrder(this.CategoryUrl)
      if (res.code !== 200) {
        Toast.fail('订单提交失败')
        return
      }
      Toast.success('订单提交成功')
      this.$dialog.alert({
        title: '订单提交成功',
        message: '可点击左上角 返回->我的->订单记录 查看',
        showConfirmButton: true,
        theme: 'round-button'
      }).then(() => {
        const backUrl = this.Category === 'goods' ? 'storeList' : 'accompanyServeStore'
        this.$router.replace('/' + backUrl)
        // window.uni.reLaunch({
        //   url: '../orderHistory/orderHistory'
        // })
      })
    }
  },
  computed: {
    totalPrices () {
      return this.productList.reduce((pre, cur) => {
        const AllPrice = pre + cur.number * cur.real_price
        return Math.floor(AllPrice * 100) / 100
      }, 0)
      // const map1 = this.productList.map(item => item.num * item.price)
      // return map1
    }
  },
  components: {
    orderFormProduct,
    selectHarvestAddress,
    loadingPage
  }
}
</script>

<style lang="less" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: all 1s;
  }
  .fade-leave-to  {
    opacity: 0;
    transform: translateY(100%);
  }
  .fade-enter{
    opacity: 0;
    transform: translateY(100%);
  }
  .orderform-container{
    .orderform-address{
      height: 120px;
      padding: 35px 30px;
      margin-top: 20px;
      background-color: white;
      display: flex;
      .address-img{
        width: 60px;
        height: 120px;
        text-align: center;
        vertical-align: middle;
        line-height: 120px;
        img{
          width: 60px;
          position: relative;
          margin-top: 30px;
        }
      }
      // 联系人/地址信息
      .no-address-people{
        width: 590px;
        height: 120px;
        line-height: 120px;
        font-size: 30px;
        text-align: center;
        font-weight: 700;
      }
      .address-people{
        width: 590px;
        padding: 0 20px;
        .people-box{
          font-size: 25px;
          height: 25px;
          line-height: 25px;
          margin-bottom: 25px;
          .call-phone{
            margin-left: 25px;
            color: #919191;
          }
        }
        //地址信息
        .address-info{
          line-height: 35px;
          height: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 27px;
        }
      }
    }
    //安全提示
    .product-safety{
      margin: 0 auto;
      margin-top: 60px;
      width: 620px;
      padding: 30px 35px;
      background: white;
      border-radius: 15px;
      display: flex;
      .safety-img{
        width: 35px;
        height: 80px;
        img{
          margin-top: 20px;
          height: 35px;
          width: 35px;
        }
      }
      .hint{
        margin-left: 20px;
        height: 80px;
        line-height: 40px;
        font-size: 25px;
        color:#919191;
      }
    }
    //提交
    .get-btn-box {
      position: fixed;
      background: white;
      bottom: 0;
      width: 690px;
      padding: 0 30px;
      height: 185px;
      // text-align: right;
      display: flex;
      .get-price{
        margin-top: 30px;
        flex: 1;
        height: 75px;
        line-height: 75px;
        font-size: 28px;
        span{
          font-size: 30px;
          color: #FE4D3D;
          font-weight: bold;
        }
      }
      .get-btn{
        text-align: right;
        button {
            width: 185px;
            height: 75px;
            border-radius: 10px;
            margin-top: 30px;
          }
      }
    }
  }
</style>
