// 获取传来的code
// console.log(getQuery('data'));  //获取 uni-app 传来的值//取url中的参数值
export const getQuery = url => {
  // 通过 ? 分割获取后面的参数字符串

  const urlStr = url.split('?')[1]
  if (urlStr) {
    // 创建空对象存储参数
    const obj = {}
    // 再通过 & 将每一个参数单独分割出来
    const paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      const arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1]
    }
    return obj
  }
}

export const sessionQueryTokenId = () => {
  const TokenId = getQuery(window.location.href)
  if (TokenId) {
    sessionStorage.setItem('openid', decodeURIComponent(TokenId.openid))
    sessionStorage.setItem('token', decodeURIComponent(TokenId.token))
  } else {
    return false
  }
}

// 获取id并补齐0
export const PrefixZero = (num, n) => {
  return (Array(n).join(0) + num).slice(-n)
}

// 获取标签最多3个
export const getOtherTag = (TagArr, num) => {
  let nowTagArr
  if (TagArr.length > num) {
    nowTagArr = TagArr.slice(0, num)
  } else {
    nowTagArr = TagArr
  }
  return nowTagArr
}
