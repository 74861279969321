<template>
  <div class="selectharvestaddress-container">

    <div class="shade-box">
      <div class="close-title">
        <div class="close" @click="closeSelectAddress">×</div>
        <div class="title">请选择配送地址</div>
      </div>
      <div class="address-list">
          <addressInfo v-for="item in adressList" :key="item.id"
          :addressId= "item.id"
          :address="item.address"
          :area_name="item.area_name"
          :area="item.area"
          :city_name="item.city_name"
          :province_name="item.province_name"
          :recipient_name="item.recipient_name"
          :recipient_phone="item.recipient_phone"
          :defaultState="item.default"
          @OpenEditAddress="OpenEdit"
          @checkMyAddress="checkMyAddress"
          ></addressInfo>
      </div>
      <div class="btn-box">
        <van-button type="primary" size="large" @click="addAddress"><span>＋</span>添加收货地址</van-button>
      </div>
    </div>
    <transition name="fade">
      <editAddress v-if="showEditAddress" :addressId="addressId" @closeEditAddress="closeEdit" :EditAddressDetail="EditAddressDetail"></editAddress>
    </transition>
  </div>
</template>

<script>
import addressInfo from '@/components/selectHarvestAddress/component/addressInfo.vue'
import editAddress from '@/components/selectHarvestAddress/editAddress.vue'
import { getMyAddress } from '@/api/goodsApi.js'
import { Toast } from 'vant'
export default {
  name: 'selectHarvestAddress',
  data () {
    return {
      showEditAddress: false,
      adressList: [],
      addressId: 0,
      EditAddressDetail: {}
    }
  },
  created () {
    this.getAddressList()
  },
  methods: {
    // 关闭添加修改页
    closeEdit () {
      console.log(222)
      this.showEditAddress = false
      this.getAddressList()
    },
    // 修改地址信息
    OpenEdit (addressId, EditAddressDetail) {
      this.addressId = addressId
      this.showEditAddress = true
      this.EditAddressDetail = EditAddressDetail
      console.log(EditAddressDetail)
    },
    // 选定地址
    checkMyAddress (checkAddressDetail) {
      this.closeSelectAddress()
      this.$emit('getCheckMyAddress', checkAddressDetail)
    },
    closeSelectAddress () {
      this.$emit('closeSelectAddress')
    },
    // 获取所有地址
    async getAddressList () {
      Toast.loading({
        message: '',
        forbidClick: true
      })
      const res = await getMyAddress()
      Toast.clear()
      if (res.code !== 200) {
        Toast.fail('地址列表拉取失败')
      }
      this.adressList = res.data
      console.log(res)
    },
    // 添加地址
    addAddress () {
      this.EditAddressDetail = {}
      this.addressId = 0
      this.showEditAddress = true
    }

  },
  components: {
    addressInfo,
    editAddress
  }
}
</script>

<style lang="less" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: all 1s;
  }
  .fade-leave-to  {
    opacity: 0;
    transform: translateY(100%);
  }
  .fade-enter{
    opacity: 0;
    transform: translateY(100%);
  }
  .selectharvestaddress-container{
      width: 750px;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      // background-color: rgba(0, 0, 0,.5);
      padding-top: 135px;
    .shade-box{
      border-radius: 20px 20px 0 0;
      overflow: hidden;
      position: relative;
      height: 93%;
      background: white;
      .close-title{
        padding: 10px 20px;
        display: flex;
        line-height: 60px;
        background: #F5F5F5;
        .close{
          font-size: 50px;
        }
        .title{
          flex: 1;
          text-align: center;
          font-size: 30px;
          font-weight: bold;
        }
      }
      .address-list{
        height: 85%;
        overflow-y: auto;
      }
      .btn-box{
        position: absolute;
        bottom: 0;
        padding: 0 40px;
        width: 670px;
        height: 165px;
        border-top: 1px solid #ccc;
        text-align: center;
        padding-top: 20px;
        background: white;

      }
    }

  }
</style>
