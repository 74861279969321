<template>
  <div class="sociaworkerdetail-container">
      <loadingPage :isload="loadState"></loadingPage>
      <!--  社工信息 -->
      <div class="sociaworker-info-detail">
        <!-- 社工照片 -->
        <div class="worker-pic">
          <img :src="headimg" alt="">
        </div>
        <div class="worker-detail">
          <div class="name-id">
            <div class="name">{{detailInfo.name}}</div>
            <div class="id">NO.{{ workerId }}</div>
          </div>
          <div class="woker-info">
            <span>{{ detailInfo.age }}岁</span>
            <span class="dian">·</span>
            <span>{{ detailInfo.work_time }}年经验</span>
            <span class="dian" v-if="requestUrl === 'socialworker'">·</span>
            <span v-if="requestUrl === 'socialworker'">{{ CERTIFICATENAME[detailInfo.certificate] }}</span>
            <span class="dian" v-if="requestUrl === 'socialworker'">·</span>
            <span v-if="requestUrl === 'socialworker'">{{Eucation[detailInfo.education]}}</span>
            <!-- <span class="dian">·</span>
            <span>{{ detailInfo.address}}</span> -->
          </div>
          <div class="label">
            <div class="label-tag" v-for="(item, key) in tagList" :key="key">{{ item }}</div>
          </div>
        </div>
      </div>
      <!-- 个人介绍/证书 -->
      <div class="introduce-ccie">
        <div class="select-button-box">
          <div class="button-box">
            <div :class="checkLookContentType === 1 ? 'active' : ''" @click="checkLookContentType=1">个人介绍</div>
            <div v-if="requestUrl === 'socialworker'" :class="checkLookContentType === 2 ? 'active' : ''" @click="checkLookContentType=2">证书情况</div>
          </div>
          <div class="green-btn-border " :class="checkLookContentType === 2 ? 'green-border-move' : 'green-border-out'">
          </div>
        </div>
        <div class="button-content">
          <div class="introduce-content" v-if="checkLookContentType === 1">
            {{ detailInfo.self}}
          </div>
          <div class="ccie-content" v-if="checkLookContentType === 2">
            {{ CERTIFICATENAME[detailInfo.certificate] }}
          </div>
        </div>
      </div>
      <!-- 服务流程 -->
      <div class="service-process">
        <div class="title">服务流程</div>
        <div class="service-content">
          <div class="servebox">
            <img src="@/assets/sociaworkerdetail/process1.png" class="serve-icon" alt="">
            <div class="processname">发布需求</div>
          </div>
          <div class="jt">
            <img src="@/assets/sociaworkerdetail/jt.png" alt="">
          </div>
          <div class="servebox">
            <img src="@/assets/sociaworkerdetail/process2.png" class="serve-icon" alt="">
            <div class="processname">电话确认</div>
          </div>
          <div class="jt">
            <img src="@/assets/sociaworkerdetail/jt.png" alt="">
          </div>
          <div class="servebox">
            <img src="@/assets/sociaworkerdetail/process3.png" class="serve-icon" alt="">
            <div class="processname">系统派单</div>
          </div>
          <div class="jt">
            <img src="@/assets/sociaworkerdetail/jt.png" alt="">
          </div>
          <div class="servebox">
            <img src="@/assets/sociaworkerdetail/process4.png" class="serve-icon" alt="">
            <div class="processname">约定时间</div>
          </div>
          <div class="jt">
            <img src="@/assets/sociaworkerdetail/jt.png" alt="">
          </div>
          <div class="servebox">
            <img src="@/assets/sociaworkerdetail/process5.png" class="serve-icon" alt="">
            <div class="processname">开始服务</div>
          </div>
        </div>

      </div>

      <!-- 用户评价 -->

      <div class="evaluation-list">
        <div class="title">用户评价</div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <evaluationInfo v-for="item in commentList" :key="item.id"
          :id="item.id"
          :nickname="item.nickname"
          :avatar-url="item.avatarUrl"
          :content="item.content"
          :create_time="item.create_time"
          :commentpic="item.commentpic"
          ></evaluationInfo>
        </van-list>
      </div>
      <!-- 底部按钮 -->
      <div class="contact-booking">
        <van-button type="primary" size="normal" @click="telephone">电话咨询</van-button>
        <!-- <van-button type="primary" size="normal" @click="appointment">立即预约</van-button> -->
        <div class="nav-height"></div>
      </div>
  </div>
</template>

<script>
import evaluationInfo from '@/components/evaluationInfo.vue'
import loadingPage from '@/components/loadingPage.vue'
import { PrefixZero } from '@/utils/my.js'
import CERTIFICATE from '@/utils/socialWorker.js'
import { getSocialWokerDetail, getSocialWokerComment } from '@/api/socialworker.js'
export default {
  name: 'sociaWorkerDetail',
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    requestUrl: {
      type: String,
      default: 'socialworker'
    }
  },
  data () {
    return {
      page: 1,
      list_rows: 7,
      requestObj: { // 文件名
        socialworker: 'social',
        nurs: 'nurs'
      },
      detailInfo: [],
      checkLookContentType: 1,
      headimg: '',
      tagList: [],
      commentList: [],
      // 当页面下拉到底部时会自动把 loading 改为 true ,此时滚动底部将不再触发
      // 当数据加载完成必须要把 loading 改为 false，不然无法再次触发
      loading: true,
      // 当页面全部数据获取完成时 将 finished 改为 true 将会不在触发，从而 没有更多数据了
      finished: false,
      loadState: true
      // 排序选项
    }
  },
  created () {
    this.getSocialWokerDetailInfo()
    this.getUserComment()
  },
  mounted () {
    this.$nextTick(() => {
      document.addEventListener('UniAppJSBridgeReady', function () {
        // alert("jsbridge")
        /* eslint-disable */
        window.uni.getEnv(function (res) {
          console.log('当前环境：' + JSON.stringify(res))
        })

      })
    })
  },
  methods: {
    // 获取当前社工信息
    async getSocialWokerDetailInfo () {
      const res = await getSocialWokerDetail(this.requestUrl, this.id)

      if(res.code !== 200){
        this.$toast({
          type: 'fail',
          message: '社工信息拉取失败',
          forbidClick: true
        })
        return
      }
      this.detailInfo = res.data
      this.headimg = 'https://up.bgyly.top/upload/'+ this.detailInfo.pic
      this.loadState = false
      if (this.detailInfo.tag.length >= 3) {
        this.tagList = this.detailInfo.tag.slice(0, 3)
      } else {
        this.tagList = this.detailInfo.tag
      }
      // console.log(this.detailInfo)
    },
    // 获取当前社工的用户评论
    async getUserComment () {
      const res = await getSocialWokerComment(this.requestUrl, this.requestObj[this.requestUrl], this.id, { page: this.page, list_rows: this.list_rows })

      if (res.code !== 200) {
        this.$toast({
          type: 'loading',
          message: '加载中...',
          forbidClick: true
        })
      }
      this.commentList = [...this.commentList, ...res.data.data]
      this.loading = false
      if (this.page >= res.data.last_page) {
        this.finished = true
      }

    },
    // 滚动加载
    onLoad () {
      this.page++
      this.getUserComment()
    },
    telephone () {
      window.location.href = 'tel:0577-68688990'
    },
    appointment () {
      this.$router.push('/appointmentSocialWorker/' + this.id)
    }
  },
  computed: {
    workerId () {
      return PrefixZero(this.id, 4)
    },
    CERTIFICATENAME () {
      return CERTIFICATE.certificateName
    },
    Eucation () {
      return CERTIFICATE.educationName
    }
  },
  components: {
    evaluationInfo,
    loadingPage
  }
}
</script>

<style lang="less" scoped>

  .sociaworkerdetail-container{
    width: 690px;
    margin: 0 auto;
    padding-bottom: 200px;
    .sociaworker-info-detail{
      margin-top: 30px;
      margin-bottom: 20px;
      background-color: white;
      border-radius: 10px;
      padding: 15px 20px 20px 20px;
      // overflow: hidden;
      display: flex;
      .worker-pic{
        border-radius: 10px;
        margin-top: 5px;
        margin-right: 20px;
        font-size: 0;
        overflow: hidden;
        img{
          width: 175px;
          height: 230px;
        }
      }
      .worker-detail{
        width: 450px;
        .name-id{
          font-size: 18px;
          height: 50px;
          display: flex;
          overflow: hidden;
          .name{
            line-height: 50px;
            height: 50px;
            font-size: 40px;
            font-weight: bold;
            margin-right: 25px;
          }
          .id{
            background-color: rgba(255,120,0, .2);
            color: #FF7800;
            font-size: 25px;
            padding: 0px 20px;
            border-radius: 10px;
            line-height: 45px;
            height: 45px;
          }
        }
        .woker-info{
          margin-top: 25px;
          font-size: 30px;
          height: 70px;
          line-height: 35px;
          overflow: hidden;
          .dian{
            font-weight: bold;
            margin: 0 10px;
          }
        }
        .label{
          margin-top: 35px;
          height: 45px;
          display: flex;
          overflow: hidden;
          .label-tag{
            padding: 0px 5px;
            line-height: 45px;
            margin-right: 10px;
            border:1px solid #4FBC6D;
            color: #4FBC6D;
            box-sizing: border-box;
            font-size: 25px;
            border-radius: 5px;
          }
        }
      }
    }
    //个人介绍/证书
    .introduce-ccie{
      background-color: white;
      padding: 0 20px 20px 20px;
      border-radius: 10px;
      .select-button-box{
        width: 630px;
        margin: 0 auto;
        font-size: 30px;
        border-bottom: 1px solid #cccc;
        padding-bottom: 20px;
        .button-box{
          display: flex;
          padding-top: 30px;
          .active{
            font-size: 31px;
            font-weight: bold;
          }
          div{
            text-align: center;
            margin-right: 70px;
            height: 35px;
            line-height: 35px;
          }
        }
        .green-btn-border{
          border:3px solid #4FBC6D;
          width: 80px;
          margin-top: 10px;
          margin-left: 20px;
          background-color: #4FBC6D;

        }
        .green-border-move{
          transform: translateX(190px);
          transition: all .5s;
        }
        .green-border-out {
          transform: translateX(5px);
          transition: all .5s;
        }
      }
      .button-content{
        margin-top: 30px;
        line-height: 40px;
        font-size: 27px;
      }
    }
    //内容标题统一样式
    .title {
      font-size: 31px;
      font-weight: bold;
      width: 630px;
      margin: 0 auto;
      padding-bottom: 30px;
      border-bottom: 1px solid #ccc;
    }
    //服务流程
    .service-process{
      background-color: white;
      border-radius: 10px;
      padding: 30px 20px;
      margin-top: 20px;
      .service-content {
        margin-top: 30px;
        line-height: 40px;
        font-size: 27px;
        display: flex;
        .servebox{
          text-align: center;
          flex: 1;
          .serve-icon{
            width: 65px;
            height: 65px;
          }
          .processname{
            text-align: center;
            font-size: 24px;
          }
        }
        .jt{
          img{
            width: 32px;
            height: 32px;
            margin-top:25px;
          }
        }
      }
    }
    // 用户评价内容框
    .evaluation-list{
      background-color: white;
      border-radius: 10px;
      padding: 30px 20px;
      margin-top: 20px;

    }
    // 电话咨询以及预约按钮
    .contact-booking{
      position: fixed;
      width: 750px;
      bottom: 0;
      left: 0;
      padding: 30px;
      background-color: white;
      text-align: center;
      box-shadow: 0px 3px 5px #ccc;
      .nav-height{
        height: 50px;
      }
      button{
        &:first-of-type{
          margin-right: 50px;
        }
        font-size: 26px;
        height: 75px;
        width: 320px;
        border-radius: 10px;
        // margin-right: 30px;
      }
    }
  }
</style>
