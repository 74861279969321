<template>
  <div class="storescearchlist-container">
    <!-- <rowTwoStore v-for="n in 3" :key="n"></rowTwoStore> -->
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">
      <div class="my-store">
        <rowTwoStore v-for="(item, index) in goodsList" :key="index"
        :goods_index="index"
        :goods_id="item.id"
        :name="item.name"
        :distribution_region="item.distribution_region"
        :cover="item.cover"
        :real_price="item.real_price"
        :original_price="item.original_price"
        :show_price="item.show_price"
        :show_original_price="item.show_original_price"
        :Category="Category"
        ></rowTwoStore>
      </div>
    </van-list>
  </div>
</template>

<script>
import rowTwoStore from '@/components/storeListInfo/store/rowTwoStore.vue'
import { getGoods } from '@/api/goodsApi.js'
export default {
  name: 'storeScearchList',
  props: {
    Category: {
      type: String,
      default: 'goods'
    }
  },
  data () {
    return {
      page: 1,
      page_size: 5,
      loading: true,
      // 当页面全部数据获取完成时 将 finished 改为 true 将会不在触发，从而 没有更多数据了
      finished: true,
      searchvalue: '',
      goodsList: []
    }
  },
  created () {
    // this.getGoodList()
    // this.goodsList = []
  },
  methods: {
    async getGoodList () {
      const res = await getGoods({
        page: this.page,
        page_size: this.page_size,
        search: this.searchvalue
      }, this.Category)
      this.goodsList = [...this.goodsList, ...res.data.list]
      this.loading = false
      if (this.page >= res.data.list_total) {
        this.finished = true
      } else {
        this.finished = false
      }
      console.log(this.loading)
    },
    searchGoodsList (searchvalue) {
      if (searchvalue !== '') {
        this.searchvalue = searchvalue
        // this.finished = false
        this.goodsList = []
        this.page = 1
        // this.finished = false
        this.getGoodList()
      }
    },
    // 下拉加载更多
    onLoad () {
      this.getGoodList()
      console.log(111)
      this.page++
    }
  },
  components: {
    rowTwoStore
  }
}
</script>

<style lang="less" scoped>

  .storescearchlist-container{
    width: 690px;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    // display: flex;
    overflow-y: scroll;
    .my-store{
      width: 690px;
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>
