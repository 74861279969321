<template>
  <div class="editaddress-container">
    <div class="shade-box">
      <div class="close-title">
        <div class="close" @click="closeEditAddress">×</div>
        <div class="title">地址详情</div>
      </div>
      <div class="address-list">
          <van-address-edit
            :area-list="areaList"
            :show-delete="addressId!==0"
            :address-info="AddressInfo"
            show-set-default
            show-search-result
            :search-result="searchResult"
            :area-columns-placeholder="['请选择', '请选择', '请选择']"
            @save="onSave"
            @delete="onDelete"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { areaList } from '@vant/area-data'
import { saveAddress, deleteSelectAddress } from '@/api/goodsApi.js'

export default {
  name: 'editAddress',
  props: {
    addressId: {
      type: Number,
      default: 0
    },
    EditAddressDetail: {
      type: Object
    }
  },
  data () {
    return {
      areaList,
      searchResult: [],
      AddressInfo: []
    }
  },
  created () {
    this.AddressInfo = this.EditAddressDetail
  },
  methods: {
    closeEditAddress () {
      this.$emit('closeEditAddress')
    },
    async onSave (content) {
      Toast.loading({
        message: '',
        forbidClick: true
      })
      console.log(content)
      const defaultState = content.isDefault ? 1 : 0
      const res = await saveAddress({
        address_id: this.addressId,
        recipient_name: content.name,
        recipient_phone: content.tel,
        area: content.areaCode,
        address: content.addressDetail,
        default: defaultState
      })
      Toast.clear()
      if (res.code !== 200) {
        Toast.fail('地址添加失败')
      }
      Toast.success('添加成功')
      this.closeEditAddress()
      console.log(res)
    },
    async deletAddress () {
      const res = await deleteSelectAddress({
        address_id: this.addressId
      })
      if (res.code !== 200) {
        Toast.fail('删除失败')
      }
      Toast.success('删除成功')
      this.$emit('closeEditAddress')
      console.log(res)
    },
    onDelete () {
      this.deletAddress()
    }
  }
}
</script>

<style lang="less" scoped>
  .editaddress-container{
      width: 750px;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      // background-color: rgba(0, 0, 0,.5);
      padding-top: 135px;
    .shade-box{
      border-radius: 20px 20px 0 0;
      overflow: hidden;

      height: 100%;
      background: white;
      .close-title{
        padding: 10px 20px;
        display: flex;
        line-height: 60px;
        background: #F5F5F5;
        .close{
          font-size: 50px;
        }
        .title{
          flex: 1;
          text-align: center;
          font-size: 30px;
          font-weight: bold;
        }
      }
      .address-list{
        height: 85%;
        overflow-y: auto;    // 提交数据
        background-color: #f5f5f5;
        /deep/.van-button--danger{
          background: #4FBC6D;
          border:1px solid #4FBC6D;
        }
        /deep/.van-switch--on{
          background: #4FBC6D;
        }
      }
    }

  }
</style>
