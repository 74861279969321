<template>
  <!-- 路由占位符 -->
  <router-view v-wechat-title="$route.meta.title" />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
  body{
    margin: 0;
    background-color: #F4F4F4;
  }

</style>
