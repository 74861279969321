<template>
  <div class="rowtwostore-container">
    <div class="row-store" :class="(goods_index + 1) % 2 == 0 ? 'marginr-zero':''" @click="navToDetail(goods_id)">
        <div class="store-img">
          <img :src="'https://up.bgyly.top/upload/' +cover" alt="">
        </div>
        <!-- 商品名称 -->
        <div class="store-name">{{ name }}</div>
        <div class="over-address">
          <span>配送地区：{{ distribution_region }}</span>
        </div>
        <div class="price">
          <div class="now-price">￥{{ show_price }}</div>
          <div class="old-price">￥{{ show_original_price }}</div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'rowTwoStore',
  props: {
    goods_index: {
      type: Number,
      default: 0
    },
    goods_id: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    distribution_region: {
      type: String,
      default: ''
    },
    cover: {
      type: String,
      default: ''
    },
    real_price: {
      type: [String, Number],
      default: ''
    },
    original_price: {
      type: [String, Number],
      default: ''
    },
    show_price: {
      type: String,
      default: ''
    },
    show_original_price: {
      type: String,
      default: ''
    },
    Category: {
      type: String,
      default: 'goods'
    }
  },
  methods: {
    navToDetail (id) {
      this.$router.push(`/productDetail/${id}/${this.Category}`)
    }
  }
}
</script>

<style lang="less" scoped>
  @import url('@/utils/style.less');
  .rowtwostore-container{
    // width: 315px;

      background-color: white;
      padding: 20px 10px;
      width: 315px;
      margin-right: 5px;
      margin-left: 5px;
      margin-top:20px;
      border-radius: 10px;
      float: left;
      .store-img {
        width: 315px;
        text-align: center;
        font-size: 0;
        img {
          width: 315px;
        }
      }
      .store-name {
        width: 315px;
        margin-top:20px;
        font-size: 30px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    .marginr-zero {
      margin-right: 0!important;
    }
    .over-address {
      font-size: 20px;
      display: flex;
      margin-top: 20px;
      span {
        border-radius: 10px;
        padding: 8px 10px;
        color: @green-other;
        background-color: @green-label-bg;
      }
    }
    .price {
      margin-top: 2.666667vw;
      overflow: hidden;
      height: 7.333333vw;
      line-height: 7.333333vw;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .now-price {
        float: left;
        color: #FF7800;
        font-size: 27px;
        font-weight: bold;
        margin-right: 20px;
      }

      .old-price {
        float: left;
        font-size: 23px;
        color: #939393;
        text-decoration: line-through;
      }
    }
  }
</style>
