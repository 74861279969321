<template>
  <div class="evaluationinfo-container">
    <div class="user-head">
      <div class="headimg">
        <img :src="avatarUrl" alt="">
      </div>
      <div class="user-info">
        <div class="user-name">{{ nickname }}</div>
        <div class="user-time">{{ create_time }}</div>
      </div>
    </div>
    <!-- 用户评论 -->
    <div class="uesr-evaluation">
      {{ content }}
    </div>
    <!-- 照片上传 -->
    <div class="user-photo">
      <div class="img-box" v-for="(item, index) in imgList" :key="index" @click="lookMoreImg(index)">
        <van-image show-loading show-error fit="cover" :src="item" />
        <div class="more-page" v-show="moreState && index === 3">
          +{{moreNum}}
        </div>
      </div>
      <!-- <div class="img-box"><van-image show-loading show-error fit="cover" src="https://img01.yzcdn.cn/vant/cat.jpeg" /></div>
      <div class="img-box"><van-image show-loading show-error fit="cover" src="http://www.dhkjwl.com/h5/jindihaisi_wj/images/6/bg.jpg" /></div>
      <div class="img-box"><van-image show-loading show-error fit="cover" src="https://img01.yzcdn.cn/vant/cat.jpeg" /></div> -->
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  name: 'evaluationInfo',
  props: {
    id: {
      type: Number,
      default: 0
    },
    nickname: {
      type: String,
      default: ''
    },
    avatarUrl: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    create_time: {
      type: String,
      default: ''
    },
    commentpic: {
      type: Array
    }
  },
  data () {
    return {
      imgList: [],
      imgContentList: [],
      moreState: false,
      moreNum: 0
    }
  },
  created () {
    this.GetImg()
  },
  methods: {
    GetImg () {
      this.commentpic.forEach(item => {
        this.imgList.push(`https://up.bgyly.top/upload/${item.pic_url}`)
      })
      this.imgContentList = this.imgList
      if (this.imgList.length > 4) {
        this.moreNum = 9 - this.imgList.length
        this.imgList = this.imgList.slice(0, 4)
        this.moreState = true
      }
    },
    lookMoreImg (index) {
      ImagePreview({ images: this.imgContentList, startPosition: index })
    }
  }
}
</script>

<style lang="less" scoped>

  .evaluationinfo-container{
    margin-top: 35px;
    .user-head{
      display: flex;
      .headimg{
        font-size: 0;
        img{
          height: 80px;
          width: 80px;
          background-color: #ccc;
          border-radius: 50%;
        }
      }
      .user-info{
        width: 320px;
        margin-left: 20px;
        height: 80px;
        div{
          &:last-of-type{
            color: #BBBBBB;
          }
          height: 40px;
          line-height: 40px;
          font-size: 25px;
        }
      }
      .rate-box{
        width: 210px;
        line-height: 80px;
        margin-left: 20px;
      }
    }
    //用户评论
    .uesr-evaluation{
      color:#BBBBBB;
      margin-top: 20px;
      font-size: 27px;
    }
    // 评价图片
    .user-photo{
      margin-top: 20px;
      display: flex;
      .img-box{
        &:last-of-type{
          margin: 0;
        }
        width: 140px;
        height: 140px;
        margin-right: 30px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        .more-page{
          position: absolute;
          top: 0;
          left: 0;
          width: 140px;
          height: 140px;
          line-height: 140px;
          color: white;
          font-size: 60px;
          text-align: center;
          background-color: rgba(0, 0, 0, .5);
        }
      }
      .van-image{
        width: 140px;
        height: 140px;

      }
    }
  }
</style>
