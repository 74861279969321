const certificateName = {
  1: '初级社工',
  2: '中级社工',
  3: '高级社工'
}
const educationName = {
  1: '小学',
  2: '初中',
  3: '高中',
  4: '大专',
  5: '本科',
  6: '研究生',
  7: '博士'
}
export default {
  certificateName,
  educationName
}
