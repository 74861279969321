<template>
  <div class="storelist-container">

    <!-- 搜索栏 -->
    <div class="search-box">
      <van-search :show-action="isAction" action-text v-model="searchValue" clearable placeholder="请输入搜索关键词"
        @focus='focus' @input="input" @cancel="onCancel" />

    </div>

    <!-- 商品列表 -->
    <div class="store-list">
      <keep-alive>
        <component :is="isShow" Category="accompany" ref="FrontSearch"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import storeScearchList from '@/components/storeListInfo/storeScearchList.vue'
import storeTabList from '@/components/storeListInfo/storeTabList.vue'
import { sessionQueryTokenId } from '@/utils/my.js'
import { getGoodsCategory } from '@/api/goodsApi.js'
// import { getsocialwoker } from '@/api/socialworker.js'
export default {
  name: 'storeList',
  data () {
    return {
      searchValue: '',
      isShow: 'storeTabList',
      isBlur: false,
      isAction: false,
      getInfo: '',
      openid: ''
    }
  },
  mounted () {
    sessionQueryTokenId()
    this.getGoodsCategoryList()
  },
  methods: {
    async getGoodsCategoryList () {
      const res = await getGoodsCategory('accompany')
      console.log(res)
    },
    postMessage () {
      /* eslint-disable */
      console.log(uni.webView)
      // uni.webView.reLaunch({
      //   url: '../index/index'
      // })

      // uni.webView.postMessage({
      //   data: {
      //     action: 'message',
      //     name: 'click-uni'
      //   }
      // })
    },

    focus () {
      this.isShow = 'storeScearchList'
      this.isBlur = false
      this.isAction = true
    },
    //搜索消息传子组件
    input () {
      this.$refs.FrontSearch.searchGoodsList(this.searchValue)
    },
    onCancel () {
      this.isShow = 'storeTabList'
      this.isAction = false
    }
  },
  components: {
    storeScearchList,
    storeTabList
  }
}
</script>

<style lang="less" scoped>

  .storelist-container{
    padding-top: 120px;
    .search-box {
      position: fixed;
      width: 750px;
      height: 100px;
      top: 0;
      left: 0;
      .van-search{
        height: 100px;
      }
      /deep/.van-search__action{
        font-size: 30px;
      }
      /deep/.van-icon{
        font-size: 30px;
      }
      /deep/.van-field__left-icon{
        line-height: 70px;
      }
      /deep/.van-search{
        padding: 0 30px;
      }
      /deep/input{
        // width: 400px;
        height: 70px;
        font-size: 27px;
      }
    }
    //商品列表
    .store-list{
      width: 750px;
      height: 88vh;
      overflow: hidden;
    }
  }

</style>
