<template>
  <div class="productdetail-container" >
    <loadingPage v-if="loadingState"></loadingPage>
    <van-tabs v-model="active" scrollspy sticky color="#4FBC6D" line-height=".8vw" line-width="8vw">

      <van-tab title="基本信息">
        <van-swipe @change="onChange">
          <van-swipe-item v-for="imgurl in GoodsDetails.image" :key="imgurl.id">
            <img :src="'https://up.bgyly.top/upload/'+imgurl.url" width="100%" alt="">
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/{{picLength}}</div>
          </template>
        </van-swipe>
        <!-- 价格/数量 -->
        <div class="product-price-box">
          <!-- 价格 -->
          <div class="product-price">
            <div class="label" v-if="leaseCheckDetail.on_sale === 1">限时特惠</div>
            <div class="price">
              <span class="now">￥{{ leaseCheckDetail.real_price }}{{leaseCheckDetail.price_unit!=''?'/'+leaseCheckDetail.price_unit:''}}</span>
              <span class="old" v-if="leaseCheckDetail.on_sale === 1">￥{{ leaseCheckDetail.original_price }}</span>
            </div>
          </div>
          <!-- 剩余数量 -->
          <div class="product-residue">
            <div class="residue-name">设备剩余数量</div>
            <div class="residue-num">{{ GoodsDetails.residue_number > 99 ? '99+' : GoodsDetails.residue_number }}件</div>
          </div>
        </div>
        <!-- 商品姓名/选购数量 -->
        <div class="product-title-box ">
          <div class="product-name">
            {{ GoodsDetails.name }} [押金：{{ GoodsDetails.cash_pledge }}]
          </div>
          <div class="product-address-num">
            <div class="over-address">
              <span>配送地区：{{ GoodsDetails.distribution_region }}</span>
            </div>
            <div class="product-stepper">
              <van-stepper v-model="number" disable-input :max="GoodsDetails.residue_number" />
            </div>
          </div>
          <!-- 获取日租月租信息 -->
          <div class="date-lease" :class="leaseList.length >= 5 ? 'data-lease-more-type':''">
            <div class="lease-detail" v-for="(item, index) in leaseList"
            :key="index" @click="checkLeaseTime(item.attrbute_id,index)"
            :class="[attrbuteId == item.attrbute_id?'active':'',leaseList.length < 5 ? 'lease-more':'']">
              <van-image show-loading show-error fit="cover" :src="'https://up.bgyly.top/upload/'+item.cover" />
              <div class="lease-time">{{ item.attrbute_name }}</div>
            </div>
          </div>
        </div>
        <!-- 商品详情 -->
        <div class="product-detail">
          <div class="product-title">
            商品详情
          </div>
          <div class="product-detail-font">
            {{ GoodsDetails.details }}
          </div>
        </div>
      </van-tab>
      <van-tab title="图文详情">
        <!-- 图文详情 -->
        <div class="product-picdetail">
          <div class="product-title">
            图文详情
          </div>
          <p class="goods-describe" v-html="GoodsDetails.describe"></p>
        </div>
        <!-- 租赁指南 -->
        <div class="lease-guide">
          <div class="product-title">
            租赁指南
          </div>
          <img src="http://192.168.124.22:8080/img/leaseguide.1b254824.jpg" alt="">
        </div>
      </van-tab>
    </van-tabs>
    <div class="lease-btn-box">
      <van-button type="info" @click="serveCall">联系我们</van-button>
      <van-button type="primary" @click="navOrderForm" :disabled="disabled">立即预约</van-button>
    </div>
  </div>
</template>

<script>
import loadingPage from '@/components/loadingPage.vue'
import { getGoodsDetail, submitGoodsOrder } from '@/api/goodsApi.js'
import { Toast } from 'vant'
export default {
  name: 'productDetail',
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    Category: {
      type: [Number, String],
      default: 'goods'
    }
  },
  data () {
    return {
      current: 0,
      active: 1,
      number: 0,
      GoodsDetails: [],
      picLength: 5,
      leaseList: [],
      attrbuteId: 0,
      leaseCheckDetail: {},
      disabled: false,
      loadingState: true,
      CategoryUrl: ''
    }
  },
  created () {
    this.getGoodsDetails()
  },
  methods: {
    onChange (index) {
      this.current = index
    },
    navOrderForm () {
      this.submitGoodsOrder()
      // this.$router.push('/orderForm')
    },
    async getGoodsDetails () {
      if (this.Category === 'goods') {
        this.CategoryUrl = ''
      } else {
        this.CategoryUrl = this.Category + '_'
      }
      const res = await getGoodsDetail({
        id: this.id
      }, this.Category)
      this.loadingState = false
      if (res.code !== 200) {
        Toast.fail('商品获取失败')
        return
      }
      this.loadpage = true
      this.GoodsDetails = res.data
      this.picLength = this.GoodsDetails.image.length
      this.leaseList = this.GoodsDetails.attribute
      this.leaseCheckDetail = this.leaseList[0]
      this.attrbuteId = this.leaseList[0].attrbute_id
      if (this.GoodsDetails.residue_number <= 0) {
        this.disabled = true
        this.$toast({
          type: 'none',
          message: '当前商品已卖完',
          forbidClick: true
        })
      }
    },
    // 提交商品订单
    async submitGoodsOrder () {
      if (this.attrbuteId <= 0) {
        this.$toast({
          type: 'fail',
          message: '请选择租赁类型',
          forbidClick: true
        })
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const res = await submitGoodsOrder({
        attrbute_id: this.attrbuteId,
        goods_id: this.id,
        number: this.number
      }, this.CategoryUrl)
      Toast.clear()
      if (res.code !== 200) {
        Toast.fail('订单提交失败')
        return
      }
      this.$router.push(`/orderForm/${this.Category}`)
    },
    // 选择租赁时间
    checkLeaseTime (attrbuteId, myCheckIndex) {
      this.attrbuteId = attrbuteId
      this.leaseCheckDetail = this.leaseList[myCheckIndex]
    },
    serveCall () {
      window.location.href = 'tel:0577-68688990'
    }
  },
  components: {
    loadingPage
  }
}
</script>

<style lang="less" scoped>

@import url('@/utils/style.less');

  .productdetail-container{
    padding-bottom: 205px;
    .van-swipe__track{
      img{
        width: 750px;
      }
    }
    .custom-indicator {
        position: absolute;
        right: 30px;
        bottom: 40px;
        padding: 3px 15px;
        font-size: 15px;
        color: white;
        border-radius: 20px;
        background: rgba(0, 0, 0, 0.5);
    }
    //商品价格-剩余数量
    .product-price-box{
      margin: 20px 0;
      width: 750px;
      height: 175px;
      background: url(@/assets/productDetail/pricebg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      .product-price{
        padding:25px 0 0 30px;
        width: 430px;
        .label{
          display: inline-block;
          padding:0 25px;
          height: 60px;
          line-height: 60px;
          font-size: 30px;
          color: #FE4D3D ;
          background-color: rgba(255, 255, 255, .7);
          border-radius: 30px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 15px;
        }
        .price{
          color:white;
          display: flex;
          height: 50px;
          line-height: 50px;
          .now{
            font-size: 40px;
            font-weight: bold;
          }
          .old{
            font-size: 30px;
            color: rgba(255, 255, 255, .7);
            text-decoration: line-through;
            margin-left:30px
          }
        }
      }
      .product-residue{
        margin-left: 55px;
        width: 235px;
        padding: 35px 0;
        text-align: center;
        color: white;
        .residue-name{
          font-size: 27px;
          height: 45px;
          line-height: 45px;
        }
        .residue-num{
          margin-top: 10px;
          display: inline-block;
          padding: 0 20px;
          font-size: 25px;
          height: 50px;
          border-radius: 25px;
          line-height: 50px;
          background-color: rgba(0, 0, 0, .3);
        }
      }
    }
    // 商品名称/配送地址/购买数量
    .product-title-box{
      padding: 30px 30px;
      background: white;
      margin-top: 20px;

      .product-name{
        font-size: 30px;
        font-weight: bold;
      }
      .product-address-num{
        height: 50px;
        margin-top: 15px;
        display: flex;
        .over-address{
          font-size: 25px;
          flex: 1;
          span{
            border-radius: 10px;
            padding: 10px 20px;
            line-height: 50px;
            color: @green-other;
            background-color: @green-label-bg;
          }
        }
        .product-stepper{

          /deep/button,/deep/.van-stepper__input{
            height: 50px;
            width: 50px;
          }
        }
      }
      // 租赁时间
      .data-lease-more-type{
        justify-content:space-between;
      }
      .date-lease{
        display: flex;
        flex-wrap: wrap;
          .lease-more{
            margin-left: 20px;
            &:first-of-type{
              margin-left: 0;
            }
          }
        .lease-detail{
          padding: 10px;
          border:1px solid #f5f5f5;
          display: flex;
          // margin-right: 40px;
          border-radius: 10px;
          margin-top: 20px;

          .van-image{
            width: 50px;
            height: 50px;
          }
          .lease-time{
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            margin-left: 10px;
          }
        }
        .active{
          border: 1px solid #4DC06D;
          background-color: rgba(77,192,109, .1);
        }
      }

    }
    // 商品详情
    .product-detail{
      padding: 0 0 35px 0;
      margin-top: 20px;
      background: white;
      .product-detail-font{
        padding: 0 30px;
        font-size: 27px;
        line-height: 50px;
        color: #494948;
      }
    }
    //图文详情
    .product-picdetail,.lease-guide{
      margin-top: 20px;
      background: white;
      overflow: hidden;
      img {
        width: 750px;
      }
      .goods-describe{
        width: 750px;
        font-size: 27px;
        img{
          width: 100% !important;
        }
        // overflow: hidden;
      }
    }
    // 商品页标题统一样式
    .product-title{
      font-weight: bold;
      font-size: 27px;
      padding: 35px 30px;
      background: white;
    }
    // 租赁按钮
    .lease-btn-box{
      position: fixed;
      background: white;
      bottom: 0;
      width: 750px;
      height: 185px;
      text-align: right;
      button{
        width: 185px;
        height: 75px;
        border-radius: 10px;
        margin-top: 30px;
        margin-right: 30px;
      }
    }
    .van-tab__pane{
      font-size: 0;

    }
    /deep/.van-tab--active{
      color: #323233;
      font-weight: 700;
    }

  }
</style>
