<template>
  <div class="socialworkerlist-container">
    <loadingPage :isload="loadState"></loadingPage>
    <!-- 显示地址与人数 -->
    <div class="socialworkerlist-header">
      <div class="head-box">
        <div class="pos-loaction">苍南</div>
        <div class="workernum">共<span>{{ total }}</span>位{{ this.requestUrl === 'socialworker' ? '社工' : '护理员' }}</div>
      </div>
      <!-- 选择栏 -->
      <div class="nav-select-bar">
        <div class="nav-work-type" @click="checkBtnType(1)" v-if="requestUrl === 'socialworker'">
          {{service}}
          <img src="@/assets/socialWorkerList/upselect-icon.png" class="check-icon"
            :class="checkBtn === 1 ?'up-active':'up'" alt="">
        </div>
        <div class="nav-sort" @click="checkBtnType(2)">
          {{sort}}
          <img src="@/assets/socialWorkerList/upselect-icon.png" class="check-icon"
            :class="checkBtn === 2 ?'up-active':'up'" alt="">
        </div>
      </div>
      <!-- 选择服务类型列表 -->
      <transition name="fadeservice">
        <div class="service-item-content" v-show="checkBtn === 1">
          <div class="service-item">
            <div class="servoce-title">服务内容</div>
            <!-- 服务内容列表 -->
            <div class="servoce-list-content">
              <div v-for="(item, index) in serviceBtnList" :key="item.id"
                :class="[(index + 2) % 3 == 0 ? 'margin-rt' : '', item.value === 2 ? 'check-active' : '']"
                @click="checkServeOption(item.id, index)">{{item.name }}</div>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="service-btn">
            <van-button plain type="primary" size="normal" @click="resetServoce">重置</van-button>
            <van-button type="primary" size="normal" @click="getCheckService">确定</van-button>
          </div>
        </div>
      </transition>
      <!-- 选择排序方式 -->
      <transition name="fadeservice">
        <div class="sort-list" v-show="checkBtn === 2">
          <div v-for="(item, index) in sortList" :key="'sortid'+index" :class="sort == item ? 'active':''"
            @click="getSort(index)">
            {{ item }}</div>
        </div>
      </transition>
    </div>

    <!-- 个性需求 -->
    <div class="individual-needs-box">
      <div class="indivdual-cont">
        <div class="indivdual-head">帮我找{{ this.requestUrl === 'socialworker' ? '社工' : '护理员'  }}</div>
        <div class="indivdual-head-ts">填写需求后,专属顾问为你推荐</div>
        <div class="indivdual-img-box">
          <div class="indivdual-img">
            <img src="@/assets/socialWorkerList/need1.png" alt="">
            <div class="need-container">省时省心</div>
            <div class="nedds-ts">找社工时间缩80%</div>
          </div>
          <div class="indivdual-img">
            <img src="@/assets/socialWorkerList/need2.png" alt="">
            <div class="need-container">精准推荐</div>
            <div class="nedds-ts">专属顾问服务更满意</div>
          </div>
          <div class="indivdual-img">
            <img src="@/assets/socialWorkerList/need3.png" alt="">
            <div class="need-container">随时沟通</div>
            <div class="nedds-ts">有问必答快速面试</div>
          </div>
        </div>
        <van-button type="primary" color="#4FBC6D" @click="postDemand">填写需求</van-button>
      </div>
    </div>
    <!-- 制作并导入社工列表组件 -->
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <socialWorkerListInfo v-for="item in workerListInfo" :key="item.id"
        :name="item.name"
        :pic="item.pic"
        :id="item.id"
        :work-num="item.recordcount_count"
        :age="item.age"
        :work-time="item.work_time"
        :workname="CERTIFICATENAME[item.certificate]"
        :label="requestUrl === 'socialworker' ? item.service_content : item.tag_content"
        :comment-value1="item.level"
        :request-url="requestUrl"
        >
      </socialWorkerListInfo>
    </van-list>

    <!-- 发布需求 -->
    <!-- <img src="@/assets/demand.png" class="demand-btn" alt="" @click="postDemand"> -->
  </div>
</template>

<script>
import socialWorkerListInfo from '@/components/socialWorkerListInfo.vue'
import loadingPage from '@/components/loadingPage.vue'
import { sessionQueryTokenId, getQuery } from '@/utils/my.js'
import CERTIFICATE from '@/utils/socialWorker.js'
import { getSocialWorkerTag, getsocialwoker } from '@/api/socialworker.js'
export default {
  name: 'socialWorkerList',
  data () {
    return {
      page: 1,
      list_rows: 7,
      total: 0,
      requestUrl: 'socialworker',
      workerListInfo: [],
      workname: '',
      // 当页面下拉到底部时会自动把 loading 改为 true ,此时滚动底部将不再触发
      // 当数据加载完成必须要把 loading 改为 false，不然无法再次触发
      loading: true,
      // 当页面全部数据获取完成时 将 finished 改为 true 将会不在触发，从而 没有更多数据了
      finished: false,
      // 排序选项
      sortList: ['默认排序', '好评优先', '经验优先', '等级优先'],
      sort: '默认排序',
      sortId: 0,
      checkBtn: 0,
      serviceBtnList: [], // 社工种类
      checkServiceList: [],
      checkServiceIdList: [],
      service: '服务内容',
      service_content: '',
      loadState: true
    }
  },
  created () {
    const request = getQuery(window.location.href).requestUrl
    sessionQueryTokenId()
    if (request !== undefined) {
      console.log(request)
      this.requestUrl = request
    }

    this.getSocialWorkerTagList()
    this.getSocialList()
  },
  methods: {
    // 获取社工种类列表
    async getSocialWorkerTagList () {
      const res = await getSocialWorkerTag(this.requestUrl)
      res.data.forEach(item => {
        this.serviceBtnList.push({ name: item.name, id: item.id, value: 1 })
      })
    },
    async getSocialList () {
      const res = await getsocialwoker(this.requestUrl, { page: this.page, list_rows: this.list_rows, service_content: this.service_content, level: this.sortId })
      this.loadState = false
      if (res.code !== 200) {
        this.$toast({
          type: 'fail',
          message: '社工拉取失败',
          forbidClick: true
        })
      }
      this.total = res.data.total
      this.workerListInfo = [...this.workerListInfo, ...res.data.data]
      this.loading = false
      if (this.page >= res.data.last_page) {
        this.finished = true
      }
    },
    // 滚动加载
    onLoad () {
      this.page++
      this.getSocialList()
    },
    // 选择下拉按钮
    checkBtnType (type) {
      if (type === this.checkBtn) {
        this.checkBtn = 0
        return
      }
      this.checkBtn = type
    },
    // 获取排序方式
    getSort (index) {
      this.sort = this.sortList[index]
      this.sortId = index
      this.checkBtn = 0
      this.page = 1 // 初始化页数
      this.workerListInfo = [] // 初始化数据
      this.finished = false // 初始化滚动刷新
      this.service_content = this.checkServiceIdList.toString()
      this.getSocialList()
    },
    // 选择服务内容
    checkServeOption (id, index) {
      this.serviceBtnList[index].value = this.serviceBtnList[index].value === 1 ? 2 : 1
      const checkId = this.serviceBtnList[index].id
      const checkName = this.serviceBtnList[index].name
      this.checkServiceIdList.indexOf(checkId) > -1 ? this.checkServiceIdList.splice(this.checkServiceIdList.indexOf(checkId), 1) : this.checkServiceIdList.push(checkId)
      this.checkServiceList.indexOf(checkName) > -1 ? this.checkServiceList.splice(this.checkServiceList.indexOf(checkName), 1) : this.checkServiceList.push(checkName)
      this.checkServiceList.length > 1 ? this.service = this.checkServiceList[0] + '...' : this.service = this.checkServiceList[0]
      if (this.checkServiceList.length === 0) {
        this.service = '服务内容'
      }
    },
    // 重置选择列表
    resetServoce () {
      this.checkServiceList = []
      this.checkServiceIdList = []
      const servivo = this.serviceBtnList.map((item, index) => {
        return { id: item.id, name: item.name, value: 1 }
      })
      this.serviceBtnList = servivo
      this.service = '服务内容'
    },
    // 确认服务选项
    getCheckService () {
      this.checkBtn = 0 // 初始化菜单按钮
      this.page = 1 // 初始化页数
      this.workerListInfo = [] // 初始化数据
      this.finished = false // 初始化滚动刷新
      this.service_content = this.checkServiceIdList.toString()
      this.getSocialList()
    },
    // 发布需求
    postDemand () {
      this.$router.push('/postDemand/' + this.requestUrl)
    }
  },
  computed: {
    CERTIFICATENAME () {
      return CERTIFICATE.certificateName
    }
  },
  components: {
    socialWorkerListInfo,
    loadingPage
  }
}
</script>

<style lang="less" scoped>

  @green-other: #4FBC6D;
  .socialworkerlist-container{
    width: 750px;
    padding-top: 180px;
    padding-bottom: 30px;
    .head-img{
      width: 750px;
    }
    .socialworkerlist-header{
      width: 750px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
    //头部
      .head-box {
        z-index: 2;
        display: flex;
        padding: 0 30px;
        height: 90px;
        line-height: 90px;
        background-color: white;

        .pos-loaction {
          font-size: 35px;
          font-weight: 700;
        }

        .workernum {
          font-size: 30px;
          margin-left: 25px;

          span {
            color: @green-other;
          }
        }
      }

      // 选择栏
      .nav-select-bar {
        z-index: 2;
        overflow: hidden;
        padding: 0 30px;
        font-size: 27px;
        height: 90px;
        line-height: 90px;
        background-color: white;
        border-bottom:1px solid #F3F3F3;
        div {
          .check-icon {
            width: 20px;
            height: 20px;
          }
          .up-active {
            transform: rotate(180deg);
            transition: all .2s ease;
          }

          .up {
            transform: rotate(0deg);
            transition: all .2s ease;
          }
        }

        .nav-work-type {
          float: left;
        }

        .nav-sort {
          float: right;
        }
      }
      // 服务内容
      .service-item-content{
        background-color: white;
        border-radius:0px 0px 15px 15px;
        box-shadow: 2px 4px 0px #ccc;
        z-index: 1;
        .service-item {
            padding: 0 30px;
            border-bottom: 1px solid #F3F3F3;
            .servoce-title {
              height: 100px;
              font-size: 30px;
              line-height: 100px;
            }

            // 服务内容列表
            .servoce-list-content {
              overflow: hidden;
              padding-bottom: 25px;
              .check-active{
                color: #4FBC6D;
                border: 2px solid #4FBC6D;
                box-sizing: border-box;
                background-color: rgba(79,188,109, .3);
              }
              div {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3) {
                  margin-top: 0;
                }
                float: left;
                width: 200px;
                height: 70px;
                line-height: 70px;
                background-color: #EFEFEF;
                border-radius: 10px;
                color: black;
                font-weight: bold;
                text-align: center;
                font-size: 27px;
                box-sizing: border-box;
                margin-top: 25px;
              }
              .margin-rt {
                margin-left: 45px;
                margin-right: 45px;
              }
            }
          }
          //选择的按钮
          .service-btn {
            padding: 40px 0;
            height: 80px;
            text-align: center;
            font-size: 30px;
            button{
              &:first-of-type{
                margin-right: 30px;
              }
              font-size: 27px;
              height: 80px;
              width: 180px;
              border-radius: 10px;
            }
          }

      }
      //排序方式
      .sort-list{
        width: 230px;
        text-align: center;
        float: right;
        background-color: white;
        border-radius: 0px 0px 15px 15px;
        box-shadow: 1px 1px 2px #ccc;
        div{
          height: 80px;
          line-height: 80px;
          font-size: 25px;
        }
        .active{
          color:#4FBC6D;
        }
      }
    }

    //个性需求
    .individual-needs-box{
      padding: 0 25px;
      margin-top:20px;
      .indivdual-cont{
        padding: 40px 20px;
        background-color: white;
        border-radius: 15px;
        box-shadow: 1px 1px 2px #ccc;
        text-align: center;
        button{
          width: 400px;
          height: 75px;
          border-radius: 10px;
          font-size:28px;
        }
        .indivdual-head{
          line-height: 50px;
          font-size: 38px;
          color:#4FBC6D;
          font-weight: 700;

        }
        .indivdual-head-ts{
          margin-top: 5px;
          margin-bottom: 40px;
          font-size: 28px;
          color:#919191;
        }
        .indivdual-img-box{
          display: flex;
          margin-bottom: 55px;
          .indivdual-img{
            &:nth-of-type(2){
              flex:1;
            }
            img{
              width: 80px;
              height: 80px;
              margin-bottom: 5px;
            }
            .need-container{
              font-size: 28px;
              margin-bottom: 5px;
            }
            .nedds-ts{
              color: #A7A7A7;
              font-size: 20px;
            }
          }
        }
      }
    }
    // .demand-btn{
    //   width: 95px;
    //   height: 95px;
    //   position: fixed;
    //   right: 30px;
    //   top: 1200px;
    // }

  }
  .fadeservice-enter,
  .fadeservice-leave-to {
    transform: translateY(-25px);
    opacity: 0;
  }
  .fadeservice-enter-active {
    transition: .5s;
  }

  .fade-enter-to {
    transform: translateX(0);
    opacity: 1;
  }
</style>
