import request from '@/utils/request.js'

// 获取社工种类
export const getSocialWorkerTag = (url, params = {}) => {
  return request({
    method: 'GET',
    url: `/${url}/socialtag`,
    params
  })
}
// 获取社工列表
export const getsocialwoker = (url, params = {}) => {
  return request({
    method: 'GET',
    url: `/${url}/index`,
    params
  })
}

// 获取社工详情
export const getSocialWokerDetail = (url, id) => {
  return request({
    method: 'GET',
    url: `/${url}/info/${id}`
  })
}

// 获取当前社工的用户评论信息
export const getSocialWokerComment = (url, requestName, id, params = {}) => {
  return request({
    method: 'GET',
    url: `/${url}/${requestName}infocomment/${id}`,
    params
  })
}

// 预约时获取当前社工信息
export const getSocialworkerRecord = (id) => {
  return request({
    method: 'GET',
    url: '/socialworker/add/' + id
  })
}
// 提交预约社工信息
export const SocialworkerRecord = (url, params) => {
  return request({
    method: 'post',
    url: `/${url}/record`,
    params
  })
}

// 拉取所有服务
export const getAllServe = (url) => {
  return request({
    method: 'GET',
    url: `/${url}/socialtag`
  })
}
