<template>
  <div class="rowonestore-container" @click="navToDetail(id)">
    <!-- 商品图片 -->
    <img :src="'https://up.bgyly.top/upload/'+cover" class="store-img" alt="">
    <div class="store-name">{{ name }}</div>
    <div class="over-address">
      <span>配送地区：{{ distribution_region }}</span>
    </div>
    <div class="price">
      <div class="now-price">￥{{ show_price }}</div>
      <div class="old-price">￥{{ show_original_price }}</div>
      <van-button type="primary" color="#4FBC6D">立即预约</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rowOneStore',
  props: {
    id: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    cover: {
      type: String,
      default: ''
    },
    original_price: {
      type: [String, Number],
      default: ''
    },
    real_price: {
      type: [String, Number],
      default: ''
    },
    distribution_region: {
      type: String,
      default: ''
    },
    show_price: {
      type: String,
      default: ''
    },
    show_original_price: {
      type: String,
      default: ''
    },
    Category: {
      type: String,
      default: 'goods'
    }
  },
  methods: {
    navToDetail (id) {
      this.$router.push(`/productDetail/${id}/${this.Category}`)
    }
  }
}
</script>

<style lang="less" scoped>

  @import url('@/utils/style.less');
  .rowonestore-container{
    margin-top: 30px;
    width: 460px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: 30px;
    border-bottom: @border-store;
    .store-img{
      width: 460px;
      border-radius: 10px;
    }
    .store-name{
      margin:20px 0px;
      font-size: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .over-address{
      font-size: 20px;
      display: flex;
      span{
        border-radius: 10px;
        padding: 10px;
        color: @green-other;
        background-color: @green-label-bg;
      }
    }
    .price{
      margin-top: 2.666667vw;
      overflow: hidden;
      height: 7.333333vw;
      line-height: 7.333333vw;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .now-price{
        float: left;
        color:#FF7800;
        font-size: 27px;
        font-weight: bold;
        margin-right: 0px;
      }
      .old-price{
        float: left;
        font-size: 23px;
        color: #939393;
        text-decoration: line-through;
      }
      /deep/.van-button--normal{
        padding: 0px 15px;
      }
      button{
        float: right;
        height: 55px;
        border-radius: 8px;
        font-size: 20px;
      }
    }
  }
</style>
