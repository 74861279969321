<template>
  <div class="orderfromproduct-container">
    <!-- 商品列表 -->
    <div class="product-list">
      <img src="@/assets/orderForm/delete.png" class="delete-img" alt="" @click="deleteProduct(id)">
      <div class="product-img">
        <img :src="'https://up.bgyly.top/upload/'+cover" alt="">
      </div>
      <!-- 商品详情 -->
      <div class="product-info">
        <div class="product-title">{{ name }}</div>
        <!-- 价格/数量 -->
        <div class="product-price-number">
          <div class="price">￥{{ price }}{{price_unit!=''?'/'+price_unit:''}}</div>
          <div class="stepper">
            <van-stepper v-model="number" disable-input @change="changeNum(id,number)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orderFormProduct',
  props: {
    id: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    price: {
      type: [Number, String],
      default: 0
    },
    num: {
      type: Number,
      default: 0
    },
    cover: {
      type: String,
      default: ''
    },
    price_unit: {
      type: String,
      default: ''
    },
    attrbute_id: {
      type: [Number, String]
    }
  },
  data () {
    return {
      number: 1
    }
  },
  created () {
    this.number = this.num
  },
  methods: {
    deleteProduct (id) {
      this.$emit('deletProduct', id)
    },
    changeNum (id, number) {
      console.log(parseFloat(number))
      this.$emit('changeProductNum', id, number, this.attrbute_id)
    }
  }
}
</script>

<style lang="less" scoped>

  .orderfromproduct-container{
    // 商品列表
    .product-list {
      position: relative;
      margin-top: 20px;
      padding: 30px;
      background: white;
      display: flex;

      .delete-img {
        position: absolute;
        right: 10px;
        width: 30px;
        top: 5px;
      }

      .product-img {
        width: 160px;
        height: 160px;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 30px;

        img {
          width: 160px;
          height: 160px;
        }
      }

      //商品详情
      .product-info {
        width: 500px;

        .product-title {
          height: 80px;
          line-height: 40px;
          font-size: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 30px;
        }

        //价格数量
        .product-price-number {
          display: flex;
          height: 50px;

          .price {
            flex: 1;
            line-height: 50px;
            font-size: 30px;
            font-weight: bold;
            color: #FE4D3D;
          }

          .stepper {
            /deep/button {
              width: 50px;
              height: 50px;
            }

            /deep/input {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
  }
</style>
