<template>
  <div class="addressinfo-container" @click="checkAddress">
    <div class='address-img'>
      <img src="@/assets/orderForm/address.png" alt="" v-if="defaultState===1">
      <div v-else class="font-icon" :class=" recipient_name.length >=3 ? 'font-active' : 0 ">{{ recipient_name.length >=3 ? recipient_name.slice(0,1) : recipient_name }}</div>
    </div>
    <!-- 地址信息/联系人信息 -->
    <div class="address-people">
      <div class="people-box">
        <span class="call-name">{{ recipient_name }}</span>
        <span class="call-phone">{{recipient_phone}}</span>
      </div>
      <!-- 地址信息 -->
      <div class="address-info">
        {{province_name}}{{city_name}}{{area_name.trim()}}{{address}}
      </div>
    </div>
    <!-- 编辑按钮 -->
    <div class='edit-img' @click.stop="OpenEditAddress()">
      <img src="@/assets/EditAddress.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'addressInfo',
  props: {
    addressId: {
      type: Number,
      default: 0
    },
    address: {
      type: String,
      default: ''
    },
    province_name: {
      type: String,
      default: ''
    },
    city_name: {
      type: String,
      default: ''
    },
    area_name: {
      type: String,
      default: ''
    },
    area: {
      type: [String, Number],
      default: ''
    },
    recipient_name: {
      type: String,
      default: ''
    },
    recipient_phone: {
      type: String,
      default: ''
    },
    defaultState: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      EditAddressDetail: {}
    }
  },
  methods: {
    OpenEditAddress () {
      const EditAddressDetail = {
        id: this.addressId,
        name: this.recipient_name,
        tel: this.recipient_phone,
        addressDetail: this.address,
        areaCode: this.area.toString(),
        isDefault: this.defaultState === 1
      }
      this.$emit('OpenEditAddress', this.addressId, EditAddressDetail)
    },
    checkAddress () {
      const checkAddressDetail = {
        address_id: this.addressId,
        recipient_name: this.recipient_name,
        recipient_phone: this.recipient_phone,
        area: this.area.toString(),
        province_name: this.province_name,
        city_name: this.city_name,
        area_name: this.area_name,
        address: this.address
      }
      // console.log(checkAddressDetail)
      this.$emit('checkMyAddress', checkAddressDetail)
    }
  }
}
</script>

<style lang="less" scoped>
  .addressinfo-container{
      height: 120px;
      padding: 35px 30px;
      background-color: white;
      border-bottom: 1px solid #f4f4f4;
      display: flex;
      .address-img{
        width: 60px;
        height: 120px;
        text-align: center;
        vertical-align: middle;
        line-height: 120px;
        img{
          width: 60px;
          position: relative;
          margin-top: 30px;
        }
      }
      .edit-img{
        width: 60px;
        height: 120px;
        text-align: center;
        vertical-align: middle;
        line-height: 120px;
        img{
          width: 50px;
          position: relative;
          margin-top: 20px;
        }
      }
      .font-icon{
        width: 60px;
        height: 60px;
        margin-top: 30px;
        line-height: 60px;
        text-align: center;
        background: #DCF3E2;
        border-radius: 50%;
        font-size: 20px;
        color: #4FBC6D;
      }
      .font-active{
        font-size: 27px;
        font-weight: 700;
      }
      // 联系人/地址信息
      .no-address-people{
        width: 590px;
        height: 120px;
        line-height: 120px;
        font-size: 30px;
        text-align: center;
        font-weight: 700;
      }
      .address-people{
        width: 590px;
        padding: 0 20px;
        .people-box{
          font-size: 25px;
          height: 25px;
          line-height: 25px;
          margin-bottom: 25px;
          .call-phone{
            margin-left: 25px;
            color: #919191;
          }
        }
        //地址信息
        .address-info{
          line-height: 35px;
          height: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 27px;
        }
      }
    }
</style>
