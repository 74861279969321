import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant, { Dialog } from 'vant'

import 'vant/lib/index.css'
import wx from 'weixin-js-sdk'
import VueWechatTitle from 'vue-wechat-title'

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Dialog)
Vue.use(wx)
Vue.use(VueWechatTitle)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
