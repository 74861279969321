<template>
  <div class="loadingpage-container" v-if="isload">
    <div class="loader-inner ball-beat">
          <div></div>
          <div></div>
          <div></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'loadingPage',
  props: {
    isload: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="less" scoped>
  .loadingpage-container{
    position: fixed;
    width: 750px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background: white;
    .loader-inner{
      text-align: center;
      top:50%;
      left:50%;
      position: absolute;
      -webkit-transform: translateY(-50%)  translateX(-50%);
      transform: translateY(-50%)  translateX(-50%);
    }
    @-webkit-keyframes ball-beat {
          50% {
              opacity: 0.2;
              -webkit-transform: scale(0.75);
              transform: scale(0.75); }

          100% {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1); } }

      @keyframes ball-beat {
          50% {
              opacity: 0.2;
              -webkit-transform: scale(0.75);
              transform: scale(0.75); }

          100% {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1); } }

      .ball-beat > div {
          background-color: #4FBC6D;
          width: 30px;
          height: 30px;
          border-radius: 100% !important;
          margin: 5px;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          display: inline-block;
          -webkit-animation: ball-beat 0.7s 0s infinite linear;
          animation: ball-beat 0.7s 0s infinite linear; }
      .ball-beat > div:nth-child(2n-1) {
          -webkit-animation-delay: 0.35s !important;
          animation-delay: 0.35s !important; }
  }
</style>
