import request from '@/utils/request.js'
// 获取商品分类列表
// goods 租赁 accompany 陪诊
export const getGoodsCategory = function (urlName) {
  return request({
    method: 'GET',
    url: urlName + '/category'
  })
}

// 获取商品列表
export const getGoods = function (params = {}, urlName) {
  return request({
    method: 'GET',
    url: urlName + '/index',
    params
  })
}

// 获取商品详情

export const getGoodsDetail = function (params = {}, urlName) {
  return request({
    method: 'GET',
    url: urlName + '/info',
    params
  })
}

// 提交当前产品订单
export const submitGoodsOrder = (params, urlName) => {
  return request({
    method: 'post',
    url: urlName + 'order/confirm_add',
    params
  })
}

// 我的订单详情
export const getMyOrder = function (urlName) {
  return request({
    method: 'GET',
    url: urlName + 'order/confirm_order'
  })
}
// 删除订单中的商品
export const deleteOrderGoods = (params, urlName) => {
  return request({
    method: 'post',
    url: urlName + 'order/confirm_del',
    params
  })
}

// 获取地址列表
export const getMyAddress = function () {
  return request({
    method: 'GET',
    url: 'address/list'
  })
}

// 修改或添加地址
export const saveAddress = (params) => {
  return request({
    method: 'post',
    url: 'address/save',
    params
  })
}

// 删除当前地址
export const deleteSelectAddress = (params) => {
  return request({
    method: 'GET',
    url: 'address/del',
    params
  })
}

// 提交选中地址
export const getcheckAddressInfo = (params) => {
  return request({
    method: 'post',
    url: '/order/address_add',
    params
  })
}

// 提交当前订单
export const submitNowOrder = (urlName) => {
  return request({
    method: 'post',
    url: '/' + urlName + 'order/submit'
  })
}

// 清空购物车所有数据 //测试用
export const testDeletAll = () => {
  return request({
    method: 'post',
    url: '/order/address_clear'
  })
}
