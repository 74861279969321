<template>
  <div class="socialWorkerListInfo-container" @click="NavSocialWorkerDetail(id)">
    <!-- /护工照片 -->
    <div class="worker_img">
      <img :src="'https://up.bgyly.top/upload/'+pic" alt="">
    </div>
    <!-- 护工信息  -->
    <div class="worker_info_box">
      <div class="worker_info">
        <div class="worker_name">{{ name }}</div>
        <div class="work_num">已服务<span>{{ workNum }}</span>单</div>
      </div>
      <div class="worker_info_msg">
        <span>{{ age }}岁</span>
        <span class="point">·</span>
        <span>{{ workTime }}年经验</span>
        <span class="point" v-if="requestUrl === 'socialworker'">·</span>
        <span v-if="requestUrl === 'socialworker'">{{ workname }}</span>
      </div>
      <div class="label_box">
        <div class="label" v-for="(item, index) in label.slice(0, 3)" :key="index">{{ item }}</div>
      </div>
      <div class="worker_star">
        <van-rate v-model="commentValue" readonly color="#FF7800" gutter="1.5vw"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'socialWorkerListInfo',
  props: {
    id: {
      type: Number,
      default: 0
    },
    pic: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    workNum: {
      type: Number,
      default: 0
    },
    age: {
      type: Number,
      default: 0
    },
    workTime: {
      type: Number,
      default: 0
    },
    workname: {
      type: String,
      default: ''
    },
    label: {
      type: Array
    },
    commentValue1: {
      type: Number,
      default: 3
    },
    requestUrl: {
      type: String,
      default: ''
    }
  },
  created () {
    this.commentValue = this.commentValue1
  },
  data () {
    return {
      commentValue: 0
    }
  },
  methods: {
    // 跳转到社工详情页
    NavSocialWorkerDetail (id) {
      this.$router.push('/sociaWorkerDetail/' + id + '/' + this.requestUrl)
    }
  }
}
</script>

<style lang="less" scoped>

  .point{
    margin:0 10px;
    font-weight: bold;
  }
  .socialWorkerListInfo-container{
    display: flex;
    width: 650px;
    margin:0 auto;
    margin-top: 20px;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 1px 1px 5px #ccc;
    .worker_img{
      margin-right: 25px;
      img{
        border-radius: 10px;
        height: 230px;
        width: 175px;
      }
    }
    // 护工信息
    .worker_info_box{
      width: 450px;
      .worker_info{
        overflow: hidden;
        height: 35x;
        line-height: 35px;
        display: flex;
        margin-bottom: 20px;
        .worker_name{
          font-size: 32px;
          font-weight: bold;
          margin-right: 30px;
        }
        .work_num{
          font-size: 27px;
          color: #919191;
          span {
              color: #FE4D3D;
            }
        }
      }
      //工作信息
      .worker_info_msg{
        overflow: hidden;
        font-size: 30px;
        height: 35px;
        line-height: 35px;
        margin-bottom: 25px;
      }
      .label_box{
        overflow: hidden;
        height: 50px;
        margin-bottom: 20px;
        .label{
          float: left;
          font-size: 22px;
          margin-right: 10px;
          padding: 5px 5px;
          border-radius: 10px;
          color: #4FBC6D;
          border:1px solid #4FBC6D;
          box-sizing: border-box;
        }
      }
      // 评分等级
      .worker_star{
        height: 35px;
        line-height: 35px;
      }
    }
  }
  /deep/.van-rate__icon{
    font-size: 33px;
  }
</style>
