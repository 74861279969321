<template>
  <div class="postdemand-container">
    <div class="postdemand-header">
      发布需求后可让更多{{ requestUrl=='socialworker'?'社工':'护理员'}}看见
    </div>
    <!-- 用户提交的数据 -->
    <div class="postdemand-info">
      <div class="title">填写需求</div>
      <van-form @submit="onSubmit">
        <div class="appointment-info">
          <div>
            <div class="info-title">所需服务：</div>
            <div class="info-inp">
              <van-field readonly clickable name="serveName" :value="serveName" placeholder="选择所需服务"
                :rules="[{ required: true, message: '请选择所需服务' }]" @click="showPicker = true" />
            </div>
          </div>
          <div>
            <div class="info-title">联系人：</div>
            <div class="info-inp">
              <van-field v-model="contact" name="contact" placeholder="联系人"
                :rules="[{ required: true, message: '请填写联系人' }]" />
            </div>
          </div>
          <div>
            <div class="info-title">地址：</div>
            <div class="info-inp">
              <van-field v-model="address" name="address" placeholder="地址"
                :rules="[{ required: true, message: '请填写地址' }]" />
            </div>
          </div>
          <div>
            <div class="info-title">预约时间：</div>
            <div class="info-inp" @click="showTime = true">
              <van-field placeholder="预约时间" name="time" :rules="[{ required: true, message: '请选择时间段' }]"
                :show-confirm="false" :value="date" @focus="forbid"/>
            </div>
          </div>
          <div>
            <div class="info-title">需求填写：</div>
            <div class="info-inp-text">
              <van-field v-model="message" name="message" rows="4" autosize type="textarea" maxlength="50"
                placeholder="请输入填写需求" show-word-limit />
            </div>
          </div>
        </div>
        <div class="btn-bottom">
          <van-button round block type="info" native-type="submit" color="#4FBC6D">提交</van-button>
        </div>
      </van-form>
    </div>
    <!-- 选项 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="serveList" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
    <!-- 选择时间 -->
    <van-calendar v-model="showTime" color="#4FBC6D" :show-confirm="false" safe-area-inset-bottom
      @confirm="onConfirmTime" />
  </div>
</template>

<script>
import { getAllServe, SocialworkerRecord } from '@/api/socialworker.js'
import { Toast } from 'vant'
export default {
  name: 'postDemand',
  props: {
    requestUrl: {
      type: String,
      default: 'socialworker'
    }
  },
  data () {
    return {
      serveName: '',
      contact: '',
      address: '',
      date: '',
      message: '',
      serveList: [],
      showPicker: false,
      showTime: false
    }
  },
  created () {
    this.getServe()
  },
  methods: {
    // 获取所有列表
    async getServe () {
      const res = await getAllServe(this.requestUrl)
      console.log(res)
      res.data.forEach(item => {
        this.serveList.push(item.name)
      })
    },
    forbid () {
      // 禁止软键盘弹出
      document.activeElement.blur()
    },
    async onSubmit (values) {
      const res = await SocialworkerRecord(this.requestUrl, {
        user_openid: sessionStorage.getItem('openid'),
        user_name: values.contact,
        user_address: values.address,
        service_content: values.serveName,
        make_time: values.time,
        remark: values.message,
        order_type: 2
      })
      if (res.code !== 200) {
        Toast.fail(res.msg)
      } else {
        Toast.success('提交成功')
        setTimeout(() => {
          this.$router.back()
        }, 2000)
      }
    },
    formatDate (date) {
      console.log(date)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm (value) {
      this.serveName = value
      this.showPicker = false
    },
    onConfirmTime (date) {
      this.showTime = false
      this.date = this.formatDate(date)
    }
  }
}
</script>

<style lang="less" scoped>

  @import '@/utils/style.less';
  .postdemand-container{
    width: 690px;
    margin: 0 auto;
    padding-top: 30px;
    .postdemand-header{
      width: 690;
      height: 200px;
      line-height: 200px;
      text-align: center;
      background: url('@/assets/demand/bg.jpg') no-repeat;
      background-size: 100% 100%;
      font-size: 38px;
      font-weight: bold;
      color: white;
      border-radius: 15px;
    }
    // 提交数据
    .postdemand-info{
      padding: 30px 20px;
      margin-top: 30px;
      border-radius: 15px;
      background-color: white;
      .appointment-info {
        margin-top: 30px;
        font-size: 30px;

        div {
          // height: 85px;
          line-height: 85px;
          overflow: hidden;

          .info-inp {
            float: left;
            width: 440px;

            .van-cell {
              padding: 0;
            }

            .van-cell__value--alone {

              font-size: 30px;
            }

            /deep/.van-cell__value--alone {
              text-align: right;
            }

            /deep/input {
              text-align: right;
              font-size: 30px;
            }

            /deep/.van-field__error-message {
              text-align: right;
            }
          }
          // 填写需求
          .info-inp-text{
            width: 650px;
            border-radius: 10px;
            background-color: #F7F7F7;
            .van-cell{
              padding: 10px;
              background-color: #F7F7F7;
            }
            /deep/.van-field__control{
              font-size: 30px;
              line-height: 35px;
              background: none;
            }
          }
          .info-title {
            float: left;
            text-align: left;
            width: 210px;
          }
        }
      }
    }
    .btn-bottom {
      width: 690px;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 30px;
      padding-bottom: 60px;
      background-color: white;
      text-align: center;

      button {
        display: inline-block;
        height: 75px;
        width: 400px;
        border-radius: 10px;
        // margin-right: 30px;
      }
    }

  }
</style>
