import Vue from 'vue'
import VueRouter from 'vue-router'

// 导入需要使用的组件
import socialWorkerList from '@/views/socialWorkerList/socialWorkerList.vue'
import sociaWorkerDetail from '@/views/sociaWorkerDetail/sociaWorkerDetail.vue'
import appointmentSocialWorker from '@/views/appointmentSocialWorker/appointmentSocialWorker.vue'
import postDemand from '@/views/postDemand/postDemand.vue'
import storeList from '@/views/storeList/storeList.vue'
import accompanyServeStore from '@/views/accompanyServeStore/accompanyServeStore.vue'
import productDetail from '@/views/productDetail/productDetail.vue'
import orderForm from '@/views/orderForm/orderForm.vue'

Vue.use(VueRouter)

const routes = [
  // 定义路由规则
  { path: '/socialWorkerList', component: socialWorkerList, meta: { title: '' } },
  { path: '/sociaWorkerDetail/:id/:requestUrl', component: sociaWorkerDetail, props: true },
  { path: '/appointmentSocialWorker/:id', component: appointmentSocialWorker, props: true },
  { path: '/postDemand/:requestUrl', component: postDemand, props: true },
  { path: '/storeList', component: storeList, meta: { title: '租赁商城' } },
  { path: '/accompanyServeStore', component: accompanyServeStore, meta: { title: '陪诊服务' } },
  { path: '/productDetail/:id/:Category', component: productDetail, meta: { title: '商品详情' }, props: true },
  { path: '/orderForm/:Category', component: orderForm, meta: { title: '我的订单' }, props: true }

  // { path: '/socialWorkerList', component: 'socialWorkerList' }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
