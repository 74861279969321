<template>
  <div class="storetablist-container">
    <loadingPage :isload="loadState"></loadingPage>
    <div class="list-tab">
      <div v-for="item in tagList" :key="item.id" :class="checkValue === item.id ? 'active':''"
        @click="checkType(item.id)">
        <img src="@/assets/storelist/pic.png" alt="" v-show="checkValue === item.id">{{ item.name }}
      </div>
    </div>
    <!-- 内容 -->
    <div class="storelist">
      <van-list v-model="loading" offset="100" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <rowOneStore v-for="item in storeList"
        :key= "item.id"
        :id= "item.id"
        :name= "item.name"
        :cover= "item.cover"
        :original_price= "item.original_price"
        :real_price= "item.real_price"
        :distribution_region= "item.distribution_region"
        :show_price="item.show_price"
        :show_original_price="item.show_original_price"
        :Category="Category"
        ></rowOneStore>
       </van-list>
    </div>
  </div>
</template>

<script>
import rowOneStore from '@/components/storeListInfo/store/rowOneStore.vue'
import loadingPage from '@/components/loadingPage.vue'
import { getGoodsCategory, getGoods } from '@/api/goodsApi.js'
import { Toast } from 'vant'

export default {
  name: 'storeTabList',
  props: {
    Category: {
      type: String,
      default: 'goods'
    }
  },
  data () {
    return {
      page: 1,
      page_size: 5,
      loading: true,
      // 当页面全部数据获取完成时 将 finished 改为 true 将会不在触发，从而 没有更多数据了
      finished: false,
      tagList: [],
      checkValue: 1,
      storeList: [],
      loadState: true,
      getState: true
    }
  },
  created () {
    this.getGoodsCategoryList()
  },
  methods: {
    async getGoodsCategoryList () {
      const res = await getGoodsCategory(this.Category)
      if (res.code !== 200) {
        Toast.fail('列表拉取失败')
        return
      }
      this.tagList = res.data
      this.checkValue = this.tagList[0].id
      this.getGoodsList()
    },
    async getGoodsList () {
      this.getState = false
      const res = await getGoods({
        page: this.page,
        page_size: this.page_size,
        category_id: this.checkValue
      }, this.Category)
      this.loadState = false
      this.getState = true
      if (res.code !== 200) {
        this.$toast({
          type: 'fail',
          message: '商品拉取失败',
          forbidClick: true
        })
      }
      this.storeList = [...this.storeList, ...res.data.list]
      this.loading = false
      if (this.page >= res.data.list_total) {
        this.finished = true
      }
    },
    checkType (id) {
      if (this.checkValue !== id) {
        if (this.getState) {
          this.page = 1
          this.checkValue = id
          this.storeList = []
          this.finished = false
          this.loading = true
          this.getGoodsList()
        }
      }
    },
    // 下拉加载更多
    onLoad () {
      this.page++
      this.getGoodsList()
    }
  },
  components: {
    rowOneStore,
    loadingPage
  }
}
</script>

<style lang="less" scoped>

.storetablist-container{
  width: 750px;
  height: 100%;
  flex: 1;
  /* flex:1占据剩余空间 */
  display: flex;
  /* 设置为flex */
  overflow: hidden;
  .list-tab{
    width: 230px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    div{
      img {
          width: 24px;
          height: 40px;
          vertical-align: middle;
          margin-right: 10px;
        }
      text-align: center;
      height: 100px;
      font-size: 27px;
      line-height: 100px;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .active{
      font-size: 30px;
      background-color: white;
      font-weight: bold;
    }
  }
  .storelist{
    flex: 1;
    height: 100%;
    padding:0 30px;
    background: white;
    overflow-y: auto;
  }
}
</style>
