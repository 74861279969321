<template>
  <div class="appointmentsocialworker-container">
    <!--  社工信息 -->
    <div class="sociaworker-info-detail">
      <!-- 社工照片 -->
      <div class="worker-pic">
        <img :src="'https://up.bgyly.top/upload/'+socialWorkerInfo.certificate_pic" alt="">
      </div>
      <div class="worker-detail">
        <div class="name-id">
          <div class="name">{{socialWorkerInfo.name}} </div>
        </div>
        <div class="serve-name">
          所需服务：{{ServeValue}}
        </div>
        <div class="label">
          <div class="label-tag" v-for="(tag, key) in tagList" :key="key">{{ tag}}</div>
          <!-- <div class="label-tag">健康美丽</div>
          <div class="label-tag">健康美丽</div>
          <div class="label-tag">健康美丽</div> -->
        </div>
        <div class="remark">
          备注：{{ CERTIFICATENAME[socialWorkerInfo.certificate] }}
        </div>
      </div>
    </div>
    <!-- 预约 -->
    <div class="appointment">
      <div class="title">用户信息填写</div>
      <van-form @submit="onSubmit">
        <div class="appointment-info">
          <div>
            <div class="info-title">联系人：</div>
            <div class="info-inp">
              <van-field v-model="contact" name="contact" placeholder="联系人"
                :rules="[{ required: true, message: '请填写联系人' }]" />
            </div>
          </div>
          <div>
            <div class="info-title">地址：</div>
            <div class="info-inp">
              <van-field v-model="address" name="address" placeholder="地址"
                :rules="[{ required: true, message: '请填写地址' }]" />
            </div>
          </div>
          <div>
            <div class="info-title">预约服务时间：</div>
            <div class="info-inp" @click="show = true">
              <van-field placeholder="预约时间" name="time" :rules="[{ required: true, message: '请选择时间段' }]"
                :show-confirm="false" :value="date" />
            </div>
          </div>
          <div>
            <div class="info-title">预约服务：</div>
            <div class="info-inp">
              <van-field
                readonly
                clickable
                name="serve"
                :rules="[{ required: true, message: '请选择服务内容' }]"
                :value="ServeValue"
                placeholder="服务内容"
                @click="showPicker = true"
              />
            </div>
          </div>
        </div>
        <div class="btn-bottom">
          <van-button round block type="info" native-type="submit" color="#4FBC6D">提交</van-button>
        </div>
      </van-form>
    </div>
    <van-calendar v-model="show" color="#4FBC6D" :show-confirm="false" safe-area-inset-bottom @confirm="onConfirm" />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onServeConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getSocialworkerRecord, SocialworkerRecord } from '@/api/socialworker.js'
import CERTIFICATE from '@/utils/socialWorker.js'
import { getOtherTag } from '@/utils/my.js'
import { Toast } from 'vant'
export default {
  name: 'appointmentSocialWorker',
  data () {
    return {
      socialWorkerInfo: [],
      tagList: [],
      columns: [],
      contact: '',
      address: '',
      ServeValue: '',
      show: false,
      showPicker: false,
      date: ''
    }
  },
  props: {
    id: {
      type: [Number, String],
      default: 0
    }
  },
  created () {
    this.getSocialworkerRecordInfo()
  },
  methods: {
    async getSocialworkerRecordInfo () {
      console.log(this.id)
      const res = await getSocialworkerRecord(this.id)
      this.socialWorkerInfo = res.data
      console.log(this.socialWorkerInfo)
      this.columns = this.socialWorkerInfo.service_content
      this.tagList = getOtherTag(this.socialWorkerInfo.tag, 3)
    },
    onServeConfirm (value) {
      this.ServeValue = value
      this.showPicker = false
    },
    // 提交用户数据
    async onSubmit (values) {
      console.log('submit', values)
      const res = await SocialworkerRecord({
        user_openid: sessionStorage.getItem('openid'),
        social_id: this.id,
        user_name: values.contact,
        user_address: values.address,
        service_content: values.serve,
        make_time: values.time,
        order_type: 1
      })
      if (res.code !== 200) {
        Toast.fail(res.msg)
      } else {
        Toast.success('提交成功')
        setTimeout(() => {
          this.$router.back()
        }, 2000)
      }
    },
    formatDate (date) {
      console.log(date)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm (date) {
      this.show = false
      this.date = this.formatDate(date)
    }
  },
  computed: {
    CERTIFICATENAME () {
      return CERTIFICATE.certificateName
    }
  }
}
</script>

<style lang="less" scoped>

  @import "@/utils/style.less";
  .appointmentsocialworker-container{
    width: 690px;
    margin: 0 auto;
    padding-bottom: 200px;
    // 日历
    /deep/.van-calendar__header-title{
      font-size: 30px;
    }
    /deep/.van-calendar__month-title,/deep/.van-calendar__header-subtitle,/deep/.van-calendar__weekday,/deep/.van-calendar__day{
      font-size: 26px;
    }
    .sociaworker-info-detail {
      margin-top: 30px;
      margin-bottom: 20px;
      background-color: white;
      border-radius: 10px;
      padding: 15px 20px 20px 20px;
      overflow: hidden;

      .worker-pic {

        float: left;
        margin-top: 5px;
        margin-right: 20px;
        img {
          width: 180px;
          height: 180px;
          border-radius: 10px;
        }
      }

      .worker-detail {
        width: 450px;
        float: left;
        .name-id {
          height: 40px;
          .name {
            line-height: 30px;
            height: 30px;
            font-size: 27px;
            font-weight: bold;
          }
        }
        .serve-name {
          margin-top: 5px;
          font-size: 27px;
          line-height: 35px;
          height: 35px;
        }

        .label {
          margin-top: 15px;
          height: 40px;
          overflow: hidden;

          .label-tag {
            float: left;
            margin-bottom: 5px;
            padding: 0px 5px;
            margin-right: 10px;
            border: 1px solid #4FBC6D;
            color: #4FBC6D;
            box-sizing: border-box;
            font-size: 15px;
            border-radius: 5px;
          }
        }
        .remark{
          margin-top: 15px;
          font-size: 27px;
        }
      }
    }
    // 用户信息填写
    .appointment{
      background-color: white;
      padding: 30px 20px 20px 20px;
      border-radius: 15px;
      .appointment-info{
        margin-top: 30px;
        font-size: 30px;

        div{
          // height: 85px;
          line-height: 85px;
          overflow: hidden;
          .info-inp{
            float: left;
            width: 440px;
            .van-cell{
              padding: 0;
            }
            .van-cell__value--alone{

              font-size: 30px;
            }
            /deep/.van-cell__value--alone{
              text-align: right;
            }

            /deep/input{
              text-align: right;
              font-size: 30px;
            }
            /deep/.van-field__error-message{
              text-align: right;
            }
          }
          .info-title{
            float: left;
            text-align: left;
            width: 210px;
          }
        }
      }
    }
    //底部按钮
    .btn-bottom{
      width: 690px;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 30px;
      background-color: white;
      text-align: center;
      button {
        display: inline-block;
        height: 75px;
        width: 400px;
        font-size: 27px;
        border-radius: 10px;
        // margin-right: 30px;
      }
    }

    // 选择器
    /deep/.van-picker-column{
      font-size: 30px;
    }
    /deep/.van-picker__toolbar{
      height: 80px;
       button{
        font-size: 30px;
       }
    }
  }
</style>
